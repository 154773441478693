









































































































import store from "@/store";
import {MemberSet} from "@/request/customer/MemberSet";

export default {
    data() {
        let self: any = this;
        return {
            showDrawer: false,
            pubOps: [],
            tableOps: [],
            loading0: false,
            loading1: false,
            editGrowthVal: false,
            growthVal: 1,
            tempGrowthVal: 1,
            formData: {
                name: '',
                lv: 1,
                growth: 1,
                desc: ''
            },
            formRules: {
                name: {required: true},
                lv: {type: 'number', required: true},
                growth: {type: 'number', required: true}
            },
            list: []
        }
    },
    created() {
        let self: any = this;
        store.dispatch('getOperates').then((ops: any) => {
            self.pubOps = ops.pubOps;
            self.tableOps = ops.tableOps;
        })
        MemberSet.getGrowth().then((body: any) => {
            self.growthVal = body.data;
        })
        self.getData();
    },
    methods: {
        getData() {
            let self: any = this;
            const msg = self.$Message.loading({
                content: '加载中...',
                duration: 0
            });
            Promise.all([MemberSet.getAll()]).then((result: Array<any>) => {
                setTimeout(msg, 500);
                self.list = result[0].data;
            })
        },
        createNew() {
            let self: any = this;
            delete self.formData.id;
            self.$refs['el-form'].resetFields();
            self.showDrawer = true;
        },
        submit0() {
            let self: any = this;
            self.editGrowthVal = !self.editGrowthVal;
            if (!self.editGrowthVal) {
                self.loading0 = true;
                self.growthVal = self.tempGrowthVal * 100;
                MemberSet.setGrowth(self.growthVal).then((body: any) => {
                    self.loading0 = false;
                    if (body.code === 0) {
                        self.$Message.info('设置成功')
                    }
                })
            } else {
                self.tempGrowthVal = (self.growthVal / 100).toFixed(2);
            }
        },
        submit1() {
            let self: any = this;
            self.$refs['el-form'].validate((b: boolean) => {
                if (b) {
                    self.loading1 = true;
                    MemberSet.save({...self.formData}).then((body: any) => {
                        self.loading1 = false;
                        if (body.code === 0) {
                            self.showDrawer = false;
                            self.getData();
                        }
                    })
                }
            })
        },
        edit(row: any) {
            let self: any = this;
            self.formData = {...row};
            self.showDrawer = true;
        },
        del(id: string) {
            let self: any = this;
            MemberSet.del([id]).then((body: any) => {
                if (body.code === 0) {
                    self.getData();
                }
            })
        }
    }

}
